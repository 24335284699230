var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Productie per type")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel", attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "align-baseline": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm2: "" } },
                      [
                        _c("DateFilter", {
                          attrs: { label: "Datum" },
                          on: { handleChange: _vm.fetchTypes },
                          model: {
                            value: _vm.date,
                            callback: function($$v) {
                              _vm.date = $$v
                            },
                            expression: "date"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: {
                        flat: "",
                        type: "spinner--center",
                        minHeight: "300px"
                      }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? _c("WeekStatisticsView", {
                      attrs: { items: _vm.types, columns: _vm.columns }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }